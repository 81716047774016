<script>
import NewsSubmenu from '@maison/components/search/submenu/NewsSubmenu';
import ProductsSubmenu from '@maison/components/search/submenu/ProductsSubmenu';
import SearchPanel from '@phoenix/mixins/components/search/SearchPanel';
import { PhxIcon } from '@phx-styleguides/jlc';

export default {
  components: {
    NewsSubmenu,
    ProductsSubmenu,
    PhxIcon,
  },

  mixins: [SearchPanel],

  computed: {
    frequentlySearchedClasses() {
      return [
        'mega-menu__panel-block',
        { 'mega-menu__panel-block--with-border search-submenu__suggestions': !this.hasQuery },
      ];
    },

    queryResultsClasses() {
      return [
        'mega-menu__panel-block',
        'mega-menu__panel-block--with-border',
        { 'search-submenu__desktop-actions': this.hasResults },
      ];
    },
  },
};
</script>

<template>
  <div>
    <div class="container">
      <div class="search-teleport-placeholder"></div>
    </div>

    <div v-if="!isLoading" class="container">
      <div v-if="hasQuery" :class="queryResultsClasses">
        <div class="mega-menu__panel-actions">
          <div class="h4">{{ queryResultText }}</div>
          <a v-if="hasResults" :href="viewAllLink" class="btn btn--link btn--icon btn--palladium">
            <span class="btn__text">{{ viewAllText }}</span>
            <span class="btn__icon">
              <phx-icon icon="chevron-16" bidirectional />
            </span>
          </a>
        </div>
      </div>

      <div v-if="hasFrequentlySearchedLinks && (!hasQuery || !hasResults)" :class="frequentlySearchedClasses">
        <div class="mega-menu__panel-actions">
          <div class="mega-menu__panel-title">{{ $t('Search.frequentlySearched') }}</div>
          <ul class="mega-menu__list-actions">
            <li v-for="link in frequentlySearchedLinks" :key="link.id">
              <a :href="link.url" class="mega-menu__link">
                <span>{{ link.title }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section v-if="isLoading" class="section section--text">
      <div class="flex flex-grid--center">
        <div class="loader loader--light">
          <div class="loader__inner"></div>
        </div>
      </div>
    </section>

    <div
      v-else-if="!hasQuery || (hasResults && (hasProducts || hasArticles))"
      class="mega-menu__panel-container mega-menu__panel-container--columns container search-submenu__results"
      data-simplebar
    >
      <div class="search-submenu__results-container">
        <news-submenu submenu-type="articles" :results-url="resultsUrl" :suggested-items="suggestedNews"></news-submenu>
        <products-submenu
          submenu-type="products"
          :results-url="resultsUrl"
          :suggested-items="suggestedProducts"
        ></products-submenu>
      </div>
    </div>
    <div v-else class="container">
      <section class="section section--text text-block text-block--centered">
        <h3 class="text-block__title h4">{{ noArticlesAndProductsText }}</h3>
      </section>
    </div>
  </div>
</template>
